<template>
  <div>
    <b-card no-body>
      <b-row>
        <b-col>
          <b-card-header>
            <b-card-title>Kullanıcılar</b-card-title>
          </b-card-header>
        </b-col>
        <b-col class="text-right pt-1 mr-1">
          <b-button
            v-b-modal.modal-edit-user
            variant="success"
            @click="newCustomerUser"
          >
            <FeatherIcon icon="PlusIcon" />
            Kullanıcı Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-table
        responsive="sm"
        :fields="usersFields"
        :items="customerUsers"
        striped
      >
        <template #cell(name)="data">
          <div class="font-weight-bold">
            {{ data.item.name }}
          </div>
          <small class="text-primary">{{ data.item.customer_user_type }}</small>
        </template>
        <template #cell(status)="data">
          <div class="text-center">
            <FeatherIcon
              v-if="data.item.status === '1'"
              icon="CheckIcon"
              class="text-success"
              size="25"
            />
            <FeatherIcon
              v-else
              icon="XIcon"
              class="text-danger"
              size="25"
            />
          </div>
        </template>
        <template #cell(control)="data">
          <b-dropdown
            split
            block
            size="sm"
            text="Güncelle"
            variant="outline-primary"
            @click="getCustomerUser(data.item.id)"
          >
            <b-dropdown-item @click="removeUser(data.item.id)">
              Kullanıcıyı Sil
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
    <b-modal
      id="modal-edit-user"
      ref="update-modal"
      title="Kullanıcı Bilgisi"
      ok-title="KAYDET"
      cancel-title="İPTAL"
      size="lg"
      centered
      ok-variant="danger"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      @ok="saveUser"
    >
      <b-form-group label="Adı Soyadı">
        <b-form-input
          v-model="formData.name"
          placeholder="Adı Soyadı"
        />
      </b-form-group>
      <b-form-group label="E-Posta">
        <b-form-input
          v-model="formData.email"
          placeholder="E-Posta"
        />
      </b-form-group>
      <b-form-group label="Kullanıcı Tipi">
        <v-select
          v-model="formData.id_customer_user_types"
          label="title"
          :options="userTypes"
          :reduce="userType => userType.id"
          placeholder="Seçiniz"
        />
      </b-form-group>
      <b-form-group label="Durum">
        <v-select
          v-model="formData.status"
          label="title"
          :options="statues"
          :reduce="status => status.id"
          placeholder="Seçiniz"
        />
      </b-form-group>
      <b-form-group>
        <label for="password">Parola</label>
        <b-input-group class="input-group-merge">
          <b-form-input
            id="password"
            v-model="formData.password"
            :type="passwordFieldType"
            placeholder="Parola"
          />
          <b-input-group-append is-text>
            <feather-icon
              :icon="passwordToggleIcon"
              class="cursor-pointer"
              @click="togglePasswordVisibility"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BButton,
  BRow,
  BCol,
  BModal,
  BFormGroup,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  name: 'Users',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BInputGroupAppend,
    BInputGroup,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      usersFields: [
        {
          key: 'name',
          label: 'Adı Soyadı',
        },
        {
          key: 'email',
          label: 'E-Posta',
        },
        {
          key: 'status',
          label: 'Durum',
          thStyle: { width: '160px', textAlign: 'center', verticalAlign: 'middle' },
        },
        {
          key: 'control',
          label: 'KONTROL',
          thStyle: { width: '160px', textAlign: 'center', verticalAlign: 'middle' },
        },
      ],
      formData: {
        name: null,
        email: null,
        password: null,
        status: 1,
        id_customers: this.$route.params.id,
        id_customer_user_types: null,
      },
      statues: [
        { id: 1, title: 'Aktif' },
        { id: 0, title: 'Pasif' },
      ],
    }
  },
  computed: {
    customerUser() {
      return this.$store.getters['customerUsers/getCustomerUser']
    },
    customerUsers() {
      return this.$store.getters['customerUsers/getCustomerUsers']
    },
    saveStatus() {
      return this.$store.getters['customerUsers/getCustomerUserSaveStatus']
    },
    removeStatus() {
      return this.$store.getters['customerUsers/getCustomerUserRemoveStatus']
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    userTypes() {
      return this.$store.getters['customerUserTypes/getCustomer_user_types']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          name: null,
          email: null,
          password: null,
          status: 1,
          id_customers: this.$route.params.id,
          id_customer_user_types: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.getCustomerUsers()
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
    removeStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.getCustomerUsers()
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
    customerUser(val) {
      if (val.id) {
        this.formData = val
      }
    },
  },
  created() {
    this.getCustomerUsers()
    this.getUserTypes()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getUserTypes() {
      this.$store.dispatch('customerUserTypes/customer_user_typesList', {
        select: ['customer_user_types.id AS id', 'customer_user_types.title AS title'],
      })
    },
    getCustomerUsers() {
      this.$store.dispatch('customerUsers/customerUsersList', {
        where: {
          'customer_users.id_customers': this.$route.params.id,
        },
      })
    },
    getCustomerUser(id) {
      this.$refs['update-modal'].show()
      this.$store.dispatch('customerUsers/customerUserView', id)
    },
    removeUser(id) {
      this.$store.dispatch('customerUsers/customerUserRemove', id)
    },
    newCustomerUser() {
      this.formData = {
        name: null,
        email: null,
        password: null,
        status: 1,
        id_customers: this.$route.params.id,
        id_customer_user_types: null,
      }
    },
    saveUser() {
      this.submitStatus = true
      this.$store.dispatch('customerUsers/customerUserSave', this.formData)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
