<template>
  <div>
    <b-card no-body>
      <b-row>
        <b-col>
          <b-card-header>
            <b-card-title>Adresler</b-card-title>
          </b-card-header>
        </b-col>
        <b-col class="text-right pt-1 mr-1">
          <b-button
            v-b-modal.modal-edit-address
            variant="success"
            @click="newCustomerAddress"
          >
            <FeatherIcon icon="PlusIcon" />
            Adres Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-table
        responsive="sm"
        :fields="addressesFields"
        :items="addresses"
        striped
      >
        <template #cell(title)="data">
          <div class="font-weight-bold">
            {{ data.item.title }}
          </div>
          <div>{{ data.item.address }}</div>
        </template>
        <template #cell(control)="data">
          <b-dropdown
            split
            block
            size="sm"
            text="Güncelle"
            variant="outline-primary"
            @click="getCustomerAddress(data.item.id)"
          >
            <b-dropdown-item @click="removeAddress(data.item.id)">
              Adresi Sil
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
    <b-modal
      id="modal-edit-address"
      ref="update-modal"
      title="Adres Bilgisi"
      ok-title="KAYDET"
      cancel-title="İPTAL"
      size="lg"
      centered
      ok-variant="danger"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="saveAddress"
    >
      <b-form-group label="Adres Tanımı">
        <b-form-input
          v-model="modalAddress.title"
          placeholder="Adres Tanımı"
        />
      </b-form-group>
      <b-form-group label="İl">
        <v-select
          v-model="id_cities"
          label="title"
          :options="cities"
        />
      </b-form-group>
      <b-form-group label="İlçe">
        <v-select
          v-model="id_districts"
          label="title"
          :options="districts"
          :disabled="id_cities.id == null"
        />
      </b-form-group>
      <b-form-group label="Adres">
        <b-form-textarea
          v-model="modalAddress.address"
          placeholder="Adres"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BButton,
  BRow,
  BCol,
  BModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Addresses',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BTable,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  data() {
    return {
      id_cities: {
        id: null,
        title: null,
      },
      id_districts: {
        id: null,
        title: null,
      },
      addressesFields: [
        {
          key: 'title',
          label: 'ADRES',
        },
        {
          key: 'city',
          label: 'İL',
        },
        {
          key: 'district',
          label: 'İLÇE',
        },
        {
          key: 'control',
          label: 'KONTROL',
          thStyle: { width: '160px', textAlign: 'center', verticalAlign: 'middle' },
        },
      ],
      addresses: [],
      modalAddress: {},
    }
  },
  computed: {
    cities() {
      return this.$store.getters['cities/getCities']
    },
    districts() {
      if (this.id_cities.id) {
        return this.$store.getters['districts/getDistricts']
      }
      return []
    },
    saveAddressStatus() {
      return this.$store.getters['customerAddress/getCustomerAddressSaveStatus']
    },
    removeAddressStatus() {
      return this.$store.getters['customerAddress/getCustomerAddressRemoveStatus']
    },
    customerAddressData() {
      return this.$store.getters['customerAddress/getCustomerAddresses']
    },
    modalAddressData() {
      return this.$store.getters['customerAddress/getCustomerAddress']
    },
  },
  watch: {
    id_cities(val) {
      if (val.id) {
        this.getDistricts()
      }
    },
    customerAddressData(val) {
      this.setAddresses(val)
    },
    modalAddressData(val) {
      this.setModalAddress(val)
    },
    saveAddressStatus(val) {
      if (val.status === true) {
        this.modalData = {}
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.getCustomerAddresses()
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.getCustomerAddresses()
      }
      this.submitStatus = false
    },
    removeAddressStatus(val) {
      if (val.status === true) {
        this.modalData = {}
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.getCustomerAddresses()
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.getCustomerAddresses()
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getCustomerAddresses()
    this.getCities()
  },
  methods: {
    getCustomerAddresses() {
      const params = {
        where: {
          'customer_address.id_customers': this.$route.params.id,
        },
      }
      this.$store.dispatch('customerAddress/customerAddressesList', params)
    },
    getCustomerAddress(id) {
      this.$refs['update-modal'].show()
      this.$store.dispatch('customerAddress/customerAddressView', id)
    },
    newCustomerAddress() {
      this.modalAddress = {
        title: null,
        id_cities: null,
        id_districts: null,
        address: null,
        id_customers: this.$route.params.id,
      }
    },
    getCities() {
      this.$store.dispatch('cities/citiesList', {
        select: ['cities.id AS id', 'cities.title AS title'],
      })
    },
    getDistricts() {
      if (this.id_cities.id) {
        this.$store.dispatch('districts/districtsList', {
          select: ['districts.id AS id', 'districts.title AS title'],
          where: {
            id_cities: this.id_cities.id,
          },
        })
      }
    },
    setAddresses(data) {
      this.addresses = data
    },
    setModalAddress(data) {
      this.modalAddress = data
      this.id_cities = {
        id: data.id_cities,
        title: data.city,
      }
      this.id_districts = {
        id: data.id_districts,
        title: data.district,
      }
    },
    saveAddress() {
      this.modalAddress.id_cities = this.id_cities.id
      this.modalAddress.id_districts = this.id_districts.id
      this.$store.dispatch('customerAddress/customerAddressSave', this.modalAddress)
      this.getCustomerAddresses()
    },
    removeAddress(id) {
      this.$store.dispatch('customerAddress/customerAddressRemove', id)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
