<template>
  <b-form>
    <b-card title="Firma Bilgileri">
      <div v-if="formData.company">
        <h6><strong>Firma Adı</strong></h6>
        <p class="lead">
          {{ formData.company }}
        </p>
        <hr>
      </div>
      <b-row>
        <b-col>
          <div v-if="formData.phone">
            <h6><strong>Telefon</strong></h6>
            <p class="lead">
              {{ formData.phone }}
            </p>
            <hr>
          </div>
        </b-col>
        <b-col>
          <div v-if="formData.fax">
            <h6><strong>Fax</strong></h6>
            <p class="lead">
              {{ formData.fax }}
            </p>
            <hr>
          </div>
        </b-col>
        <b-col>
          <div v-if="formData.email">
            <h6><strong>E-Posta</strong></h6>
            <p class="lead">
              {{ formData.email }}
            </p>
            <hr>
          </div>
        </b-col>
      </b-row>
      <div v-if="formData.notes">
        <h6><strong>Notlar</strong></h6>
        <p class="lead">
          {{ formData.notes }}
        </p>
        <hr>
      </div>
      <div v-if="formData.tax_office || formData.tax_number">
        <h6><strong>Vergi Dairesi / Vergi No</strong></h6>
        <p class="lead">
          {{ formData.tax_office }} {{ formData.tax_number }}
        </p>
        <hr>
      </div>
      <div v-if="formData.city || formData.district || formData.address">
        <h6><strong>Fatura Adresi</strong></h6>
        <p class="lead">
          {{ formData.address }}
          {{ formData.city }} {{ formData.district }}
        </p>
        <hr>
      </div>
      <b-button
        variant="primary"
        :to="'/customers/edit/' + formData.id"
      >
        Kartı Güncelle
      </b-button>
    </b-card>
    <addresses />
    <users />
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import Addresses from '@/views/Customers/View/Addresses'
// eslint-disable-next-line import/extensions
import Users from '@/views/Customers/View/Users'

export default {
  name: 'CustomersView',
  components: {
    BForm,
    BCard,
    BButton,
    BRow,
    BCol,
    Addresses,
    Users,
  },
  data() {
    return {
      formData: {
        company: null,
        tax_office: null,
        tax_number: null,
      },
    }
  },
  computed: {
    customerData() {
      return this.$store.getters['customers/getCustomer']
    },
  },
  watch: {
    customerData(val) {
      this.setFormData(val)
    },
  },
  created() {
    this.getCustomer()
  },
  methods: {
    getCustomer() {
      this.$store.dispatch('customers/customerView', this.$route.params.id)
    },
    setFormData(data) {
      this.formData = data
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
